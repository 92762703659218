.layoutApp {
  position: relative;
  left: 90px;
  width: calc(100vw - 100px);
  min-height: calc(100vh - 110px);
  margin-top: 20px;
}
.layoutAppResp {
  position: relative;
  width: 100%;
margin-bottom: 100px;
  margin-top: 20px;
}
