.container {
  position: relative;
  border: 1px solid #dbd8d8;
  border-radius: 5px;
  height: 44px;
}

.container:focus {
  border: 1px solid #2d6aa5;
  outline: none;
}
.buttonContainer {
  display: flex;
  height: 40px;
  background: #fff;
  font-size: 18px;
  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}

.optionsContainer {
  position: absolute;
  display: none;
  left: 0;
  top: 100%;
  width: 100%;
  height: auto;
  background-color: #fff;
  padding: 5px;
}
.optionsContainer.listOpen {
  display: block;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  border: 1px solid #2d6aa5;
  max-height: 200px;
  overflow-y: scroll;
  z-index: 1;
}

.optionsItem {
  display: flex;
  height: 30px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
}

.selected {
  background-color: #f2f2f2;
  border-radius: 8px;
}

.items {
  margin-left: 5px;
  border-radius: 5px;
  background-color: #2c659d;
  color: #fff;

  padding: 5px;
}
