.aboutUsContainer{
    
    width: 100%;
    border: 1px solid #DBD8D8;
border-radius: 5px;
}



  .aboutUsContainer:focus {
    border: 1px solid #2D6AA5;
    outline: none;
  }