
  

  .input{
    border: 1px solid #DBD8D8;
border-radius: 5px;
height: 44px;
  }

  .input:focus {
    border: 1px solid #2D6AA5;
    outline: none;
  }

  .emptyContainer{
    height: 500px;
  }
  
 

  

  