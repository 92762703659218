.containerSelect {
  position: relative;
  border: 1px solid #dbd8d8;
  border-radius: 5px;
  padding-inline: 5px;
  height: 44px;
}
.containerSelect:focus {
  border: 1px solid #2d6aa5;
  outline: none;
}

.selectButton {
  display: flex;
  height: 40px;
  width: 30%;
  background: #fff;
  align-items: center;
  align-self: center;
  cursor: pointer;
  justify-content: space-evenly;
}

.optionList {
  position: absolute;
  display: none;
  left: 0;
  top: 100%;
  width: 100%;
  height: auto;
  z-index: 1;
  padding: 5px;
  background-color: #fff;
}

.optionList.listOpen {
  display: block;
  border: 1px solid #2d6aa5;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  max-height: 220px;
  overflow-y: scroll;
}

.optionItem {
  display: flex;
  height: 30px;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
}
.optionItem:hover {
  background: #f2f2f2;
}

.icon.listOpen {
  transform: rotate(180deg);
}
.icon {
  font-size: 13px;
  color: #000;
  transform: rotate(0deg);
  transition: all 0.3s ease-in-out;
}

.pof-input {
  width: 60%;
  height: 30px;
  padding: 3px;
  outline: none;
  letter-spacing: 0.6px;
  border: 0px;
  font-size: 12px;
  font-weight: 400;
}
