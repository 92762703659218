.w10 {
  width: 10%;
}
.w10 {
  width: 10%;
}
.w20 {
  width: 20%;
}
.w30 {
  width: 30%;
}
.w40 {
  width: 40%;
}
.w45 {
  width: 45%;
}
.w50 {
  width: 50%;
}
.w60 {
  width: 60%;
}
.w70 {
  width: 70%;
}
.w80 {
  width: 80%;
}
.w95 {
  width: 95%;
}
.w90 {
  width: 90%;
}
.w100 {
  width: 100%;
}
.h44 {
  min-height: 44px;
}
.bordered {
  border: 1px solid rgb(109, 109, 109);
  border-collapse: collapse;
}
.eltList {
  overflow: hidden;
  cursor: pointer;
  transition: ALL 0.2s ease-in-out;
  word-wrap: break-word;
  word-break: break-all;
}
/* .eltList:hover {
  transform: scale(1.5);
  overflow: visible;
  background-color: aliceblue;
} */

@media only screen and (max-width: 960px) {
  .wm10 {
    width: 10%;
  }
  .wm20 {
    width: 20%;
  }
  .wm30 {
    width: 30%;
  }
  .wm40 {
    width: 40%;
  }
  .wm45 {
    width: 45%;
  }
  .wm50 {
    width: 50%;
  }
  .wm60 {
    width: 60%;
  }
  .wm70 {
    width: 70%;
  }
  .wm80 {
    width: 80%;
  }
  .wm90 {
    width: 90%;
  }
  .wm100 {
    width: 100%;
  }
}
