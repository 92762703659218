.cover {
  position: relative;
  width: 100%;
  height: 150px;
  z-index: 1;
}
.cover img {
  width: 100%;
  height: 250px;
  z-index: 1;
  border-radius: 5px;
}

.profile {
  position: relative;
  width: 180px;
  height: 180px;
  margin-left: 20px;
  z-index: 999;
}
.profile img {
  max-width: 100%;
  height: 100%;
  border-radius: 50%;
  box-shadow: 0 3px 10px #9ea6b93f;

  z-index: 999;
}
.t-r-0 {
  top: 0;
  right: 0;
}
