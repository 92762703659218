.pof-switch-checkbox {
  width: 54px;
  height: 28px;
  border-radius: 19px;
  position: relative;
  border: 1px solid rgb(145, 145, 145);
  transition: all 0.2s ease-in-out;
}
.checkboxInner {
  transform: translateX(-50%);

  width: 22px;
  height: 22px;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
}

.checkedInner {
  background-color: green;
}

.uncheckedInner {
  background-color: red;
  transform: translateX(50%);
}
