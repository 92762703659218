.loginContainer {
  height: 100vh;
  background-color: rgba(32, 25, 74, 0.1);
  background-image: url("/public/login-bg.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.mainLogoLogin {
  width: 300px;
}
.loginInner {
  width: 500px;
  background: rgb(18, 54, 84, 0.6);
  box-shadow: 0 8px 32px 0 rgb(18, 54, 84, 0.6);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
  min-width: fit-content;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
}
.loginInput {
  width: 100%;
  outline: none;
  border-radius: 5px;
  border: none;
  height: 40px;
}
.loginButton {
  transition: 0.2s ease-in-out all;
  background-color: transparent;
  font-weight: bold;
  color: #ffffff;
  border: 2px solid white;
  border-color: white;
}
.loginButton:hover {
  transform: scale(1.1);
}
.loginButton:active {
  transform: scale(1.5);
  opacity: 0.2;
}
.loginButton:disabled {
  background-color: transparent !important;
  color: grey !important;
  border-color: grey;
  transform: scale(1) !important;
  opacity: 0.5 !important;
}

@media only screen and (max-width: 1600px) {
  .loginContainer {
    min-height: calc(100vh + 25vh);
  }
}
