.alignC {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .alignS {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }
  .alignH {
    display: flex;
    align-items: center;
  }
  .alignV {
    display: flex;
    justify-content: center;
  }



  

  
  
  