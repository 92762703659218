$theme-colors: (
  "blue": #0f056b,
  "yellow": #f3cd4b,
  "lightBlue": #dae8f6,
  "grey": #f2f2f2,
  "dark": #333333,
  "darkGrey": #e2e2e2,
  "white": #ffff,
  "red": rgb(255, 0, 0),
  "green": #228b22,
  "bronze": #cd7f32,
  "silver": #c0c0c0,
  "gold": #ffd700,
  "deepBlue": #2e3660,
  "lightBlue2": #5b7bfb,
);
$grid-breakpoints: (
  xs: 0px,
  sm: 576px,
  md: 768px,
  lg: 960px,
  xl: 1200px,
  xxl: 1400px,
);

@import "~bootstrap/scss/bootstrap.scss";
