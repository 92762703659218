
.selectMenu{
  position: relative;
  border: 1px solid #DBD8D8;
  border-radius: 5px;
  padding-inline: 10px;
  height: 44px;
}
.selectMenu:focus{
  border: 1px solid #2D6AA5;
    outline: none;
}
 .selectBtn{
  display: flex;
  height: 40px;
  background: #fff;
  font-size: 18px;

  font-weight: 400;
  align-items: center;
  justify-content: space-between;
  cursor:pointer;
 
 
}



 .options{
  position: absolute;
  padding: 5px;
  
  display: none;
  left: 0;
  top: 100%;
  width: 100%;
  height: auto;
  z-index: 1;
  background-color: #fff;

}

.options.listOpen {
  display: block;
   border-bottom-left-radius: 8px;
   border-bottom-right-radius: 8px;
  border: 1px solid #2D6AA5;
    max-height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;

}

 .option{
  display: flex;
  height: 30px;
  cursor: pointer;
  align-items: center;


}
 .option:hover{
  background: #F2F2F2;
}

.icon.listOpen {
  transform: rotate(180deg);
}
.icon {
  font-size: 10px;
  color: #DBD8D8;
  transform: rotate(0deg);
  transition: all .3s ease-in-out;
}