.searchInput {
  height: 49px;
  border: 1px solid #dbd8d8 !important;
  border-radius: 5px;
  display: flex;
  width: 100%;
}
.searchInput.default {
  border-color: #363636 !important;
}
.searchInput:focus {
  border: 1px solid #2d6aa5;
  outline: none;
  padding-left: 5px;
}
.searchInput::placeholder {
  font-size: 13px #222222;
  padding: 5px;
}
