.attachUploaderButton {
    display: none;
   
  }
  
  .attachUploadLabel {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    height:44px;
    border: 1px solid #DBD8D8;
    border-radius: 5px;
    cursor: pointer;
   
  }
  
  .attachFilesText {
    font-weight: 500;
    font-size: 12px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
  }
 
  /************ COLOR PER TYPE **************/

  .photo{
    position:relative;
    width:200px;
    height:200px;
   margin-top: 10px;
}
.photo img{
   max-width:100%;
   height: 100%;
   border-radius: 45% 40%;
}

.coverP{
  position:relative;
  border-radius:5px;
  width:100%;
 margin-top: 10px;
}
.coverP img{
 max-width:100%;
 border-radius: 20px;
height: 100% 
}