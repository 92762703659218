
  
  .modalHeader {
    border-bottom: none;
  }
  .modalFooter {
    border-top: none;
  }
  
  .lightconfirmationModal .modal-content {
    background: "white";
    border-radius: 10px;
    border: none;
  }
 
  