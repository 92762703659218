@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins";
}
a {
  text-decoration: none !important;
}
.h100 {
  height: 100%;
}
svg {
  transition: all 0.2s ease-in-out;
}
.transition02 {
  transition: all 0.2s ease-in-out;
}
.pointer {
  cursor: pointer;
}

.br10 {
  border-radius: 10px;
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
/* to unset default style for btns  */
.btn-unset {
  all: unset;
}
.all-unset {
  all: unset;
}
.btn-success {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: rgb(47, 168, 47);
  display: flex;
  align-items: center;
  justify-content: center;
}
.fa-check,
.fa-xmark {
  color: white;
  font-size: 14px;
}
.btn-cancel {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: red;
  display: flex;
  align-items: center;
  justify-content: center;
}
.rounded-20 {
  border-radius: 20px;
}
.Separator {
  height: 1px;
  background-color: #68a1da;
  width: 100%;
}

@import url("./CommonStyles/flex.css");
@import url("./CommonStyles/fontSize.css");
@import url("./CommonStyles/fontweight.css");
@import url("./CommonStyles/width.css");
