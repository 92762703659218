body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ob-contain {
  width: 100%;
  max-height: 250px;
  object-fit: contain;
  object-position: center;
}

.ob-cover {
  object-fit: cover;
}

.fit-content {
  height: fit-content !important;
}

.ad-upload {
  background-color: rgb(0, 0, 0, 0.05);
  flex: 1;
  position: relative;
}

.ad-upload label {
  cursor: pointer;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}

.ad-upload label::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.2);
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  color: #ffffff88;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ad-upload label:hover::after {
  opacity: 1;
}

.ad-upload label .icons {
  display: none !important;
}

.ad-upload label:hover .icons {
  display: flex !important;
}

.ad-upload .icons {
  z-index: 1;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.ad-upload .icons > * {
  opacity: 0.8;
  transition: all 0.2s ease-in-out;
  color: rgba(255, 255, 255, 0.548) !important;
}

.ad-upload label:hover .icons > * {
  opacity: 1;
  color: rgba(255, 255, 255, 0.89) !important;
}

.h49 {
  height: 49px;
}

.reset-button {
  border-radius: 5px;
  background-color: #f2f3f8;
  border: 1px solid rgba(0, 0, 0, 0.5);
  margin: 4px;
}
