.pof-checkbox {
  width: 54px;
  height: 28px;
  border-radius: 19px;
  position: relative;
  border: 1px solid var(--bs-darkGrey);
}

.pof-checkbox:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 3px;
  transform: translateY(-50%);
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #9ea6b9;
  transition: background-color 0.2s ease-in-out, left 0.2s ease-out;
}

.pof-checkbox:after {
  background-color: var(--bs-red);
}

.pof-checkbox.checked:after {
  left: calc(100% - 22px - 3px);
  background-color: var(--bs-green);
}

.pof-checkbox.empty:after {
  left: calc(83% - 22px - 6px);
  background-color: #9ea6b9;
}
