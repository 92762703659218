.logo{
 
    position:relative;
   display: flex;
    border-radius:5px;
    width:100px;
    height:100px;
   align-self: center;
   margin-top: 10px;
    
}
.logo img{
   max-width:100%;
   height: 100%;
   border-radius: 50%;

   
}


* {
   padding: 0;
   margin: 0;
   box-sizing: border-box;
 }
 
 .scroller {
   list-style: none;
   padding: 0;
   margin: 0;
   overflow-x: scroll;
   white-space: nowrap;
 }
 
 
 .item {
   
display: inline-flex; 
flex-direction: row;  

padding-top: 5px;
padding-bottom: 10px;
justify-content: center;
   align-items: center;
   text-align: center;
 }
 .table-responsive{
   display: block;
   width: 100%;
   overflow-x: auto;
   padding-block: 10px;
  
  
 }
 .table{
   width: 100%;
   margin-bottom: 1rem;
   border: 1px solid #F2F2F2;
   border-top-left-radius: 5px;
   border-top-right-radius: 5px;
 
 }

 .itemTr:hover{
  background-color: #F2F2F2;
}

.itemTable th {
  text-align:center !important;
 
}
 
 