@keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  .loading-spinner {
    width: 50px;
    height: 50px;
    border: 8px solid #f3f3f3; /* Light grey */
    border-top: 8px solid #f3cd4b; /* Black */
    border-radius: 50%;
    animation: spinner 1.5s linear infinite;
  }

  .spinner-container{
    display: flex;
    height: 500px;
    width: "100%";
    align-items: center;
    justify-content: center;

   
    
  }