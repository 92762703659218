.coloredButton {
  height: 49px;
  outline: none;
  border: 2px solid #2c659d;
  border-radius: 5px;
}
.coloredButtonSquare {
  width: 40px !important;
}

.cercledButton {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border: 1px solid #f2f2f2;
  /* padding-right: 1.5rem !important; */
  padding: 1rem !important;
}
.cercledButtonResp {
  height: 20px;
  width: 20px;
  border-radius: 50px;
  border: 1px solid #f2f2f2;
  padding: 1rem !important;
}
