.drawerContainer {
  width: 70px;
  overflow: hidden;
  top: 86px;
  height: calc(100vh - 86px);
  position: fixed;
  z-index: 8;
  box-shadow: 0px 3px 10px 1px rgba(158, 166, 185, 0.25);
}

.drawerContainerResp {
  width: 100%;
  height: 70px;
  bottom: 1px;

  border: 2px "#f3cd4b";
  box-shadow: 0px 3px 25px #00000014;
  position: fixed;

  z-index: 1;
}

.drawerContainer:hover {
  width: 280px;
}
.drawLink {
  width: 60px;
  border-radius: 10px;
  margin: 0.25rem 0.5rem !important;
  padding-left: 1rem !important;
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
  opacity: 1;
}
.drawLinkResp {
  width: 20px;
  height: 20px;
  margin: 0.5rem 0.25rem;
  padding: 1rem !important;
}
.drawerContainer:hover .drawLink {
  width: 260px;
}
.linkName {
  white-space: nowrap;
}

.logoNavigations {
  height: fit-content;
}
.logoNavigationsResp {
  width: 90%;
}
.drawLink {
  color: #2c2c2c;
  gap: 10px;
  transform-origin: left;
}
.drawLink:hover {
  color: white;
  background-color: #0f056b;
}
.drawLink:hover svg {
  fill: white;
}
.inpage {
  background-color: #0f056b;
}

.logout {
  color: white;
  gap: 30px;
  cursor: pointer;
  position: relative;
  flex: 1;
}
